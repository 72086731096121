import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {SizedImage, EditorialImage, TextLabel} from '@/components';
import {VideoListLogo} from '@/homePage/components';
import {useDeviceType} from '@/hooks';
import {textUnderline} from '@/style';
import get from 'lodash/get';
import noop from 'lodash/noop';
import {useTranslation} from 'react-i18next';

const EditorialPlacement = ({variant = 'default', onClick = noop, placement, videoListId}) => {
  const entities = useSelector(state => state.entities);
  const hoverable = useDeviceType({mobile: false, desktop: true});

  let entity, videoList, label, imageUrl, playButton;

  if (videoListId) {
    videoList = get(entities, `videoLists.${videoListId}`);
    entity = get(entities, `videos.${videoList.latestEpisode}`);
  } else {
    entity = get(entities, `${placement.schema}.${placement.id}`);
  }

  if (entity.type === 'article') {
    label = entity.articleType;
    imageUrl = entity.previewImage;
    playButton = false;
  } else {
    label = entity.type;
    imageUrl = entity.posterUrl;
    playButton = true;
  }

  const url = entity.url;
  const title = entity.title;
  const dek = entity.dek;
  const publishedAt = entity.publishedAt;
  const author = entity.authorListForDisplay;
  const sponsorImageUrl = get(entity, 'sponsorship.sponsorImage');
  const sponsorPhrase = get(entity, 'sponsorship.sponsorPhrase');
  const sponsorImageStyle = get(entity, 'sponsorship.sponsorImageStyle');
  const titleSize = titleSizeByVariant[variant];
  const titleMargin =  titleMarginByVariant[variant];
  const showLabel = !['watchHeroDesktop', 'watchHeroMobile', 'watch'].includes(variant);
  const labelLine = variant !== 'homeTopDesktop';
  const showTopImage = !['homeTopHero', 'watchHeroDesktop', 'watchHeroMobile', 'homeTopDesktop', 'featured'].includes(variant);
  const showBottomImage = variant === 'homeTopDesktop';
  const showDek = ['homeTopHero', 'watchHeroDesktop', 'watchHeroMobile', 'featureHero'].includes(variant);
  const showPublishedAt = variant !== 'watch';
  const spaceBetween = variant === 'homeTopDesktop';
  const showLogo = ['watchHeroDesktop', 'watch'].includes(variant);
  const date = (new Date(publishedAt * 1000));
  const publishedAtForDisplay = `${monthNames[date.getMonth()]}. ${date.getDate()}, ${date.getFullYear()}`.replace('May.', 'May');
  const TitleTag = titleTagByVariant[variant];
  const {t} = useTranslation();
  const headerLabel = t(`editorial_placement.label.${label}`);
  const authorLabel = `${t('editorial_placement.author_prefix')} ${author}`;

  const sponsorImageDimensions = () => {
    const largerSponsorImage = sponsorImageStyle === 'larger';
    const dimensions = {
      height: largerSponsorImage ? 40 : 25,
    };

    if (entity.genericSponsorship) {
      dimensions.height = largerSponsorImage ? 30 : 20;
      dimensions.width = largerSponsorImage ? 135 : 90;
    }

    return dimensions;
  };

  return (
    <EditorialPlacement.Container onClick={onClick}>
      {showLogo &&
        <VideoListLogo slug={get(videoList, 'slug')} url={get(videoList, 'url')} />
      }
      <EditorialPlacement.Link href={url} spaceBetween={spaceBetween} hoverable={hoverable}>
        <EditorialPlacement.Header>
          {showLabel && <EditorialPlacement.Label line={labelLine}><TextLabel>{headerLabel}</TextLabel></EditorialPlacement.Label>}
          {showTopImage &&
            <EditorialPlacement.Image>
              <EditorialImage playButton={playButton} url={imageUrl} />
            </EditorialPlacement.Image>
          }
          <EditorialPlacement.Title size={titleSize} margin={titleMargin}>
            <TitleTag>{title}</TitleTag>
          </EditorialPlacement.Title>
          {showDek && <EditorialPlacement.Dek>{dek}</EditorialPlacement.Dek>}
        </EditorialPlacement.Header>
        <div>
          {sponsorImageUrl &&
           <EditorialPlacement.Sponsor>
             {sponsorPhrase &&
               <EditorialPlacement.SponsorPhrase>
                 <TextLabel color="background.onVariant">{sponsorPhrase}</TextLabel>
               </EditorialPlacement.SponsorPhrase>
             }
             <EditorialPlacement.SponsorImage>
               <SizedImage src={sponsorImageUrl} showPlaceholder={false} {...sponsorImageDimensions()} />
             </EditorialPlacement.SponsorImage>
           </EditorialPlacement.Sponsor>
          }
          <div>
            {author && <EditorialPlacement.Author withSlash={showPublishedAt}>{authorLabel}</EditorialPlacement.Author>}
            {showPublishedAt && <EditorialPlacement.PublishedAt>{publishedAtForDisplay}</EditorialPlacement.PublishedAt>}
          </div>
          {showBottomImage &&
            <EditorialPlacement.Image>
              <EditorialImage playButton={playButton} url={imageUrl} />
            </EditorialPlacement.Image>
          }
        </div>
      </EditorialPlacement.Link>
    </EditorialPlacement.Container>
  );
};

EditorialPlacement.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  videoListId: PropTypes.number,
  placement: PropTypes.shape({
    schema: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }),
};

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const titleSizeByVariant = {
  default: 'xSmallHeadline',
  homeTopHero: 'headline',
  homeTopDesktop: 'smallHeadline',
  watchHeroDesktop: 'smallHeadline',
  watchHeroMobile: 'smallHeadline',
  watch: 'xSmallHeadline',
  featureHero: 'smallHeadline',
  feature: 'smallHeadline',
};

const titleMarginByVariant = {
  default: 'small',
  homeTopHero: 'full',
  homeTopDesktop: 'small',
  watchHeroDesktop: 'full',
  watchHeroMobile: 'full',
  watch: 'small',
  featureHero: 'full',
  feature: 'full',
};

const titleTagByVariant = {
  default: 'h3',
  homeTopHero: 'h2',
  homeTopDesktop: 'h2',
  watchHeroDesktop: 'h2',
  watchHeroMobile: 'h2',
  watch: 'h3',
  featureHero: 'h2',
  feature: 'h3',
};

export default React.memo(EditorialPlacement);

EditorialPlacement.Container = styled.div`
  color: ${p => p.theme.color.background.on};
  font-weight: 100;
  line-height: ${p => p.theme.lineHeight.short};
  height: 100%;
  min-height: inherit;
`;

EditorialPlacement.Title = styled.div`
  margin-top: ${p => p.theme.space[p.margin]};
  font-size: ${p => p.theme.fontSize[p.size]};

  h2, h3 {
    display: inline;
  }
`;

EditorialPlacement.Link = styled.a`
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: inherit;
  ${p => p.spaceBetween && 'justify-content: space-between;'}
  ${p => p.hoverable && `
    &:hover {
      ${EditorialPlacement.Title} h2, ${EditorialPlacement.Title} h3 {
        ${textUnderline(p.theme.color.background.on, p.theme.color.background.main, p.theme.color.selection)}
      }
    }
  `}
`;

EditorialPlacement.Header = styled.div`
  margin-bottom: ${p => p.theme.space.full};
`;

EditorialPlacement.Image = styled.div`
  margin-top: ${p => p.theme.space.half};
`;

EditorialPlacement.Label = styled.div`
  display: flex;

  ${p => p.line && `
    &:after {
      content: '';
      border-bottom: ${p.theme.border.standardWidth} solid ${p.theme.color.background.on};
      flex: 1;
      margin-left: ${p.theme.space.half};
      position: relative;
      top: calc(-${p.theme.fontSize.xSmallReading}/2);
    }
  `}
`;

EditorialPlacement.Dek = styled.div`
  margin-top: ${p => p.theme.space.half};
`;

EditorialPlacement.Sponsor = styled.div`
  margin-bottom: ${p => p.theme.space.full};
`;

EditorialPlacement.SponsorPhrase = styled.div`
  margin-bottom: ${p => p.theme.space.half};
`;

EditorialPlacement.SponsorImage = styled.div`
  padding: ${p => p.theme.space.quarter};
  background: #fff;
  display: inline-block;
`;

EditorialPlacement.Author = styled.span`
  font-size: ${p => p.theme.fontSize.xSmallReading};

  ${p => p.withSlash && `
    &:after {
      content: '/';
      margin: 0 ${p.theme.space.quarter};
    }
  `}
`;

EditorialPlacement.PublishedAt = styled.span`
  font-size: ${p => p.theme.fontSize.xSmallReading};
  color: ${p => p.theme.color.background.onVariant};
`;
